import React from 'react';

export default function TextArea({label, placeholder, icon, count, id, errors, onChange, disable, onBlur,value}) {
    return (
        <div className='d-flex flex-column my-2 h-100'>
            <label htmlFor={id} className='my-1'>{label}</label>
            <div className='input-custom h-100'>
                <textarea onChange={onChange}
                          value={value}
                          disabled={disable}
                          className='p-2 my-auto '
                          autoComplete="new-password"
                          id={id}
                          onBlur={event => onBlur(event.target.value)}
                          placeholder={placeholder}/>
                {icon}
            </div>
            <div className='d-flex'>
                <p className=' error-helper' style={{fontWeight: 100}}>{errors}</p>
                <p className='ms-auto my-1 ' style={{fontWeight: 100, fontStyle: "italic"}}>{count || ' '}</p>
            </div>
        </div>
    );
}
