import React from "react";
import {store} from "../Redux";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        store.dispatch({
            type: 'CLEAN'
        });
        this.setState({hasError: true});
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1 className='text-center'>UPS. Recarga la pagina compare</h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;