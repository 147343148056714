import React from 'react';

function PageLabel({title}) {
    return (
        <div className='page-label'>
            <h1>{title}</h1>
        </div>
    );
}

export default PageLabel;