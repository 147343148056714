import {BrowserRouter, Routes, Route} from 'react-router-dom';

import LeftSidebar from "./Components/Sidebars/LeftSidebar";
import RightSidebar from "./Components/Sidebars/RightSidebar";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import Mangas from "./Pages/Mangas";
import Post from "./Pages/Post";
import Manga from "./Pages/Manga";
import ErrorBoundary from "./Pages/ErrorBoundary";
/*============= REDUX =============*/

import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {store, persist} from "./Redux";

/*============= REDUX =============*/


export default function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persist} loading={null}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path='/*' element={<HomeRoutes/>}/>
                        <Route exact path='ingresar/*' element={<LoginRoutes/>}/>
                    </Routes>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

function LoginRoutes() {
    return (
        <Routes>
            <Route exact path='/' element={<Login/>}/>
            <Route exact path='/crear_cuenta' element={<Register/>}/>
        </Routes>
    );

}

function HomeRoutes() {
    return (
        <ErrorBoundary>
            <div className='main-container'>
                <LeftSidebar/>
                <div id='scrollableMiddleContainer' className='middle-container'>
                    <Routes>
                        <Route exact path='/' element={<Home/>}/>
                        <Route exact path='/p/:id' element={<Post/>}/>
                        <Route exact path='/m' element={<Mangas/>}/>
                        <Route exact path='/m/:name' element={<Manga/>}/>
                    </Routes>
                </div>
                <RightSidebar/>
            </div>
        </ErrorBoundary>

    );
}




