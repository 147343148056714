import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {UserState} from "../Redux";

function NewPostBar({user}) {
    const navigate = useNavigate();

    const [text, setText] = useState('Crear Publicación')

    function handleButton() {
        let button = document.getElementById('button-modal');
        if (!user.isAuth) {
            setText('Redirigiendo a inicio de sesión ');
            setTimeout(() => {
                navigate('/ingresar')
            }, 1000);
        } else {
            button.setAttribute("data-bs-toggle", "modal");
        }
    }

    useEffect(() => {
        let button = document.getElementById('button-modal');

        if (!user.isAuth) {
            button.setAttribute("data-bs-toggle", "");
        } else {
            button.setAttribute("data-bs-toggle", "modal");
        }
    }, [user.isAuth])

    return (
        <div className='new-post-bar d-flex flex-row justify-content-between py-2 px-4'>
            <p className='my-auto'>{text}</p>
            <button className='new-post d-flex'
                    id='button-modal'
                    data-bs-toggle=""
                    data-bs-target="#create-post-modal"
                    onClick={() => handleButton()}
            >
                <div>
                    <span className="material-icons-outlined">add_circle</span>
                </div>
            </button>

        </div>
    );
}
export default connect(UserState)(NewPostBar);