import {store} from "../Redux";

export const url = 'https://gcb.mysweetdiamond.com';


export function authenticate(user) {
    return fetch(`${url}/authenticate`, {
        method: 'POST',
        body: JSON.stringify({
            username: user.username,
            password: user.password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function socialAuthenticate(username, idToken) {
    let urlParam = new URL(url + '/firebase/authenticate');
    let params = {username, idToken};
    if (username == null) {
        params = {idToken};
    }
    Object.keys(params).forEach(key => urlParam.searchParams.append(key, params[key]))
    return fetch(`${urlParam}`, {
        method: 'POST',
        /*body: JSON.stringify({
            username,
            idToken
        }),*/
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function isEmailAlreadyInUse(email) {
    return fetch(`${url}/user/email/${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => {
        return res.json();
    }).then(status => {

        return status.code === 200;
    })
}

export function register(user) {
    return fetch(`${url}/authenticate/createUser`, {
        method: 'POST',
        body: JSON.stringify({
            username: user.username,
            email: user.email,
            password: user.password
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export function findUser(username) {
    return fetch(`${url}/user/${username}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export function validateToken() {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/authenticate/validate/${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function validateEmail(emailToken) {
    return fetch(`${url}/authenticate/email/${emailToken}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getNews() {
    return fetch(`${url}/somoskudasai`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export function testPatch() {
    return fetch(`${url}/firebase/test`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export function getPosts(page) {
    return fetch(`${url}/forum/post/all/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function searchForums(title) {
    return fetch(`${url}/forum/search/${title}/0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function createPost(title, body) {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/forum/post/${title}`, {
        method: 'POST',
        body: JSON.stringify({
            ...body
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

/**
 *
 * @param title {string}
 * @param id {number}
 * @param body {Object}
 * @returns {Promise<Response>}
 */
export function editPost(title, id, body) {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/forum/post/${title}/${id}`, {
        method: 'POST',
        body: JSON.stringify({
            ...body
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export function getMyLatestPost() {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/forum/post/myposts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getFriends() {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/user/info/my_friends/0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getLatestMangas() {
    return fetch(`${url}/manga/gender/lasts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getMangasByGender(gender, page) {
    return fetch(`${url}/manga/gender/${gender}/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getForumsByManga(name, page) {
    return fetch(`${url}/forum/manga/${name}/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export function getMangaByName(name) {
    return fetch(`${url}/manga/${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function searchManga(name, page) {
    return fetch(`${url}/manga/search/${name}/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export function searchForumByManga(manga_name, name, page) {
    return fetch(`${url}/forum/search/${manga_name}/${name}/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getComments(post_id, page) {
    return fetch(`${url}/forum/all/comments/${post_id}/${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getPost(post_id) {
    return fetch(`${url}/forum/post/${post_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function sendMessage(post_id, comment) {
    let token = store.getState().UserReducer.user.token;
    return fetch(`${url}/forum/comment/${post_id}`, {
        method: 'POST',
        body: JSON.stringify({
            comment: comment,
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}