import {combineReducers, createStore} from "redux";

// import storageSession from 'redux-persist/lib/storage/session'
import localStorage from "redux-persist/es/storage";
import {persistStore, persistReducer} from "redux-persist";


/* ============ Reducers ============ */
import UserReducer from "./Reducers/UserReducer";
import PostReducer from "./Reducers/PostReducer";
import SubNavbarReducer from "./Reducers/SubNavbarReducer";
import MangaReducer from "./Reducers/MangaReducer";
/* ============ Reducers ============ */


const persistConfig = {
    key: 'root',
    storage: localStorage
}

export const UserState = (state, props) => ({
    props: props,
    user: state.UserReducer.user,
})
export const PostState = (state, props) => ({
    props: props,
    post: state.PostReducer.post,
    user: state.UserReducer.user,
})

export const SubNavbarState = (state, props) => ({
    props: props,
    genders: state.SubNavbarReducer.genders,
    manga: state.MangaReducer.manga,
    gender_active: state.SubNavbarReducer.genders.filter(obj => obj.active)[0],
})

export const MangaState = (state, props) => ({
    props: props,
    manga: state.MangaReducer.manga,
})


const persistedReducer = persistReducer(persistConfig, combineReducers({
    UserReducer,
    PostReducer,
    SubNavbarReducer,
    MangaReducer,
}));

export let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export let persist = persistStore(store)


/*
export default createStore(combineReducers({
    UserReducer
}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
*/
