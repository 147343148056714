const initialState = {
    genders: [
        {name: 'Shonen', active: true},
        {name: 'Seinen', active: false},
        {name: 'Yuri', active: false},
        {name: 'Yaoi', active: false},
        {name: 'Shojo', active: false},
        {name: 'Hentai', active: false},
    ]
}


const SubNavbarReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_GENDERS':
            return {
                genders: [...action.genders]
            }
        case 'SET_ACTIVE_GENDER':
            let genders = [...state.genders];
            let genders_aux = [];
            let pos = 0;

            genders.map((value, index) => {
                if (value.name === action.gender.name) pos = index;
                value.active = false;
                return genders_aux.push(value);
            })
            action.gender.active = true
            genders = genders_aux.filter(obj => obj.name !== action.gender.name);
            genders.splice(pos, 0, action.gender);

            return {
                genders
            }
        case 'CLEAN':
            return initialState;
        default:
            return state
    }


}

export default SubNavbarReducer