import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";

export default function SkeletonC({className}) {
    return (
        <div className={className}>
            <Skeleton variant='rect' width='100%' height='100%' animation='wave'/>
        </div>
    );
}
