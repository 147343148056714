export const PostDispatchToProps = dispatch => ({

    setPostAct(post) {
        dispatch({
            type: 'SET_POST',
            post
        });

    }


})