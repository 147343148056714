import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {PostState} from "../Redux";
import {PostDispatchToProps} from "../Redux/Actions/PostAction";
import PageLabel from "../Components/PageLabel";
import {formatDate} from "../Js/moment-custom";
import PostForm from "../Components/Forms/PostForm";
import Modal from "../Components/Modal";
import Button from "../Components/Buttons/Button";
import {socket} from "../Api/socket";
import {getComments, getPost, sendMessage} from "../Api/api";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonC from "../Components/Skeletons/SkeletonC";
import Comment from "../Components/Cards/Comment";
import {useParams} from "react-router-dom";

function Post({post, user, setPostAct}) {

    const id_modal = 'create-post-modal'

    const {id} = useParams();

    const [room] = useState(`post-${post.id}`);
    const [message, setMessage] = useState(``);
    const [comments, setComments] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [endMessage, setEndMessage] = useState('');

    function apiInitialCall() {
        setPage(0);
        getComments(id, page).then(res => {
            if (res.status !== 200) {
                throw Error;
            }
            return res.json();
        }).then(data => {
            if (data.data[0] === undefined) {
                setHasMore(false)
                setEndMessage('no existen comentarios')
            } else {
                setComments(data.data)
            }
        }).catch(() => {
            setComments([])
            setHasMore(false)
        })
    }

    useEffect(() => {
        if (post.id !== id) {
            getPost(id).then(res => {
                if (res.status !== 200) {
                    throw  Error;
                }
                return res.json();
            }).then(data => {
                setPostAct(data.data);
                apiInitialCall();
            }).catch(() => {
                console.log('UPS');
            })
        }
        return () => {
            setComments([]);
            setPage(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPostAct, id]);

    useEffect(() => {
        socket.connect();
        socket.emit('publication_room', {room});
        apiInitialCall();
        return () => {
            setComments([]);
            socket.disconnect();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        socket.on('comment_received', value => {
            setComments([value, ...comments])
        });
        return () => {
            socket.off('comment_received');
        }
    }, [comments])

    function handleInfiniteScroll() {
        getComments(page).then(res => {
            return res.json();
        }).then(data => {
            if (data.data.length === 0) {
                setHasMore(false);
            }
            setComments(comments.concat(data.data))
            setPage(page + 1)
        }).catch(() => {
            setHasMore(false)
        })
    }

    function sendComment() {
        if (message.trim() !== '') {
            sendMessage(post.id, message).then(res => {
                if (res.status !== 200) {
                    throw  Error
                }
                return res.json();
            }).then(data => {
                setMessage('');
                document.getElementById('comment_input').innerText = ''
                socket.emit('new_comment', {
                    room,
                    ...data.data
                });
            }).catch((e) => {
                console.log(e.message);
            })
        }
    }

    return (
        <div>
            <PageLabel title='Publicación'/>

            <Modal id={id_modal}>
                <PostForm post={post} edit={true}/>
            </Modal>

            <div className='post-content'>
                <header>
                    <h3>{post.title}</h3>
                    <div className='post-user-owner'>
                        <div className='avatar-sm-container'>
                            <img src={post.userAccount.avatar_thumbnails.sm} alt=""/>
                        </div>
                        <p>{post.userAccount.username}</p>
                        <p className='flex-grow-1'>{formatDate(post.timestamp)}</p>

                        {post.userAccount.username === user.username ?
                            <div>
                                <Button
                                    type={'button'}
                                    size='small'
                                    data_bs_toggle={'modal'}
                                    data_bs_target={'#' + id_modal}
                                    icon={<span className="material-icons-round">edit</span>}
                                    label={"Editar"}
                                />
                            </div> :
                            null}

                    </div>
                </header>

                <div className='post-content-body'>
                    <p>
                        {post.description}
                    </p>
                </div>

                <div className='comment-input my-3'>
                    {user.isAuth ? <>
                    <span className="my-auto" role="textbox" contentEditable
                          onBlur={(evt) => setMessage(evt.target.innerText)}

                          id={'comment_input'}
                    />
                        <div className='my-auto'>
                            <Button
                                type={'button'}
                                label={'Comentar'}
                                icon={<span className="material-icons-outlined me-2">send</span>}
                                func={sendComment}
                            />
                        </div>
                    </> : <p style={{opacity: 0.5, fontSize: '0.9rem'}} className='mx-auto'>Debes iniciar sesión para
                        poder comentar.</p>
                    }
                </div>

                <InfiniteScroll
                    next={() => handleInfiniteScroll()} hasMore={hasMore}
                    loader={<SkeletonC className='card-post-skeleton my-3'/>}
                    dataLength={comments.length}
                    scrollableTarget={'scrollableMiddleContainer'}
                    endMessage={
                        <p className='text-center my-4'>
                            <b>{endMessage}</b>
                        </p>
                    }
                >
                    <div className='comment-body'>
                        {comments.map((comment, key) => (
                            <Comment key={key} comment={comment}/>
                        ))
                        }
                    </div>
                </InfiniteScroll>

            </div>

        </div>
    );
}

export default connect(PostState, PostDispatchToProps)(Post);