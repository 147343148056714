import React, {useState} from "react";
import Input from "../Components/Forms/Input";
import {useFormik} from "formik";
import * as yup from 'yup'
import Button from "../Components/Buttons/Button";
import LoginNavbar from "../Components/NavBars/LoginNavbar";
import {authenticate, findUser, isEmailAlreadyInUse, socialAuthenticate} from "../Api/api";
import {connect} from "react-redux";
import {UserState} from "../Redux";
import {UserDispatchToProps} from "../Redux/Actions/UserAction";
import {Firebase, googleProvider} from "../Configs/Firebase";
import {useNavigate} from 'react-router-dom';


export function Login({setUserAct}) {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            UserName: '',
            Password: '',
        },
        validationSchema: yup.object({
            UserName: yup.string().min(3, 'Como mínimo 3 caracteres').required('El nombre es obligatorio').max(30),
            Password: yup.string().min(6, 'Como mínimo 6 caracteres').required('La contraseña es obligatorio').max(25),
        }),
        onSubmit: (form) => {
            try {
                // Llamada a la api
                setLoading(true);
                setButtonLabel('Ingresando');
                let user = {
                    username: form.UserName,
                    password: form.Password
                }
                authenticate(user).then(res => {
                    if (res.status !== 200) {
                        throw Error('Usuario o contraseña incorrecta');
                    }
                    return res.json();
                }).then(data => {
                    if (data.code === 403) {
                        throw Error('Valida tu cuenta');
                    }
                    data.data.isAuth = true;
                    setUserAct(data.data);
                    setLoading(false);
                    setButtonLabel('Ingresar');
                    navigate("/");
                }).catch(e => {
                    setLoading(false);
                    if (e.message.includes('token')) {
                        setButtonLabel('Contrase');
                    }
                    setButtonLabel(e.message);
                    setTimeout(() => {
                        setButtonLabel('Ingresar');
                    }, 2000)
                })

            } catch (e) {
                setLoading(false);
                setButtonLabel('Ingresar');
            }
        },
    })

    const [isLoading, setLoading] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Ingresar');

    const [isGoogleUserName, setGoogleUserName] = useState(false);

    const [user, setUser] = useState({});

    function googleSignIn() {
        Firebase.auth().signInWithPopup(googleProvider).then(async res => {

            let user = res.user
            let idToken = user.Aa;
            let email = user.email
            let username = null;

            let userGoogle = {
                username,
                email,
                idToken
            }

            let isEmailInUse = await isEmailAlreadyInUse(email);
            if (isEmailInUse) {
                socialAuthenticate(username, idToken).then(res => {
                    return res.json();
                }).then(data => {
                    data.data.isAuth = true;
                    saveUserState(data.data);
                    navigate("/");
                }).catch(e => {
                    console.log(e.message);
                })
            } else {
                setUser(userGoogle);
                setGoogleUserName(true);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    async function createUser() {
        let username = formik.values.UserName;
        if (username.trim() === '') {
            alert('Debes ingresar un nombre :C');
            formik.errors.UserName = 'Debes Ingresar un nombre';
            return;
        }
        let isUsernameAvailable = await findUser(username).then(res => (res.status !== 200));
        if (isUsernameAvailable) {
            socialAuthenticate(username, user.idToken).then(res => {
                return res.json();
            }).then(data => {
                data.data.isAuth = true;
                saveUserState(data.data)
                navigate("/");
            }).catch(e => {
                console.log(e.message);
            })
        }

    }

    function saveUserState(user) {
        setUser(user);
        setUserAct(user);
    }


    return (
        <div className='d-flex flex-column vh-100'>
            <LoginNavbar login={true}/>
            <div className='d-flex flex-column m-auto '>
                {
                    isGoogleUserName ? <div>
                            <h1 className='title-login my-4'>Estas a un solo paso</h1>
                            <p>Bienvenido. Crea tu nombre de usuario para que te puedes identificar con la comunidad.</p>
                            <Input
                                id='UserName'
                                label='Nombre de usuario'
                                icon={<span className="material-icons">account_circle</span>}
                                placeholder='Nombre de usuario'
                                count={formik.values.UserName.length + '/30'}
                                errors={formik.errors.UserName}
                                onChange={formik.handleChange}
                                onBlur={() => null}
                            />
                            <Button
                                func={createUser}
                                label='Crear'
                                type='button'
                            />

                        </div> :
                        <div>
                            <h1 className='title-login my-4'>Inicia Sesión</h1>

                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <Input
                                    id='UserName'
                                    label='Nombre de usuario'
                                    icon={<span className="material-icons">account_circle</span>}
                                    placeholder='Nombre de usuario'
                                    count={formik.values.UserName.length + '/30'}
                                    errors={formik.errors.UserName}
                                    onChange={formik.handleChange}
                                    onBlur={() => null}
                                />

                                <Input
                                    id='Password'
                                    label='Contraseña'
                                    icon={<span className="material-icons">lock</span>}
                                    placeholder='*********'
                                    count={formik.values.Password.length + '/25'}
                                    type='password'
                                    errors={formik.errors.Password}
                                    onChange={formik.handleChange}
                                    onBlur={() => null}
                                />
                                <p className='mb-4 text-center text-small'>¿Olvidaste la contraseña? Haz click <a
                                    href='https://www.google.com'>aquí</a></p>
                                <Button
                                    icon={<span className="material-icons">send</span>}
                                    state={isLoading}
                                    loading={isLoading}
                                    label={buttonLabel}
                                />

                                <div className='d-flex justify-content-center my-3'>
                                    <p style={{fontSize: '0.85rem', color: '#989898'}}>También puedes ingresar con redes
                                        sociales</p>
                                </div>
                                <div className='my-3 d-flex '>

                                    <div className='col-3 me-3'>
                                        <Button
                                            icon={<GoogleLogo/>}
                                            state={isLoading}
                                            loading={isLoading}
                                            func={googleSignIn}
                                            type='button'
                                            className='bg-white text-center'
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                }
            </div>


        </div>
    );

}


export default connect(UserState, UserDispatchToProps)(Login);


function GoogleLogo() {
    return (
        <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path fill="#4285F4"
                      d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                <path fill="#34A853"
                      d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                <path fill="#FBBC05"
                      d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                <path fill="#EA4335"
                      d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
            </g>
        </svg>
    );
}