import React from 'react';
import moment from "moment";
import 'moment/locale/es';
import {Puff} from "@agney/react-loading";
import {useNavigate} from "react-router-dom";
import {store} from "../../Redux";

export default function CardPost({post, index, genders}) {
    const navigate = useNavigate();

    function formatDate() {
        let gmtDateTime = moment.utc(post.timestamp || new Date(), "YYYY-MM-DD HH:mm")
        return gmtDateTime.local().fromNow();
    }

    async function goToPostPage() {

        store.dispatch({
            type: 'SET_POST',
            post,
        })

        navigate(`/p/${post.id}`)
    }


    return (
        <div onClick={() => goToPostPage()} className='card-post'>

            <header className='card-post-header'>

                <div className='manga-content'>
                    <div className='manga-content-img-container'>
                        {post.forum_portrait_url ?
                            <img className='' src={post.forum_portrait_url} alt=""/>
                            :
                            <div className='d-flex h-100'>
                                <div className='m-auto'>
                                    <Puff width="50" color={'white'}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='manga-content-body'>
                        <h5>{post.forum_title}</h5>
                        <h6>{post.manga_author}</h6>
                    </div>
                </div>

                <div className='user-owner'>

                    <div className="arrow d-none">
                        <span className="material-icons">expand_more</span>
                    </div>

                    <div className='user-owner-account'>
                        <div className='label'>
                            <h5>{post.userAccount.username}</h5>
                            <h6>{formatDate()}</h6>
                        </div>

                        <div className='avatar-container'>
                            {post.forum_portrait_url ?
                                <img className='' src={post.userAccount.avatar_thumbnails.sm} alt=""/>
                                :
                                <div className='d-flex h-100'>
                                    <div className='m-auto'>
                                        <Puff width="50" color={'white'}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>

            </header>

            <div className='card-post-body'>
                <h4>{post.title}</h4>
                <p>{post.description}</p>
            </div>

            <div className='card-post-footer'>
                <div>
                    <span className="material-icons">share</span>
                </div>

                <div className='d-flex flex-row'>

                    <div className='d-flex mx-2'>
                        <p className='me-2'>{post.count_comment}</p>
                        <span className="material-icons-sharp">question_answer</span>
                    </div>


                    <div className='d-flex'>
                        <p className='me-2'>0</p>
                        <span className="material-icons-outlined">favorite_border</span>
                    </div>
                </div>
            </div>


        </div>
    );
}


/*
<div onClick={() => goToPostPage()} className='card-post-blurred'>
            <div className={`d-flex flex-row card-post color-${index > 2 ? 0 : index} my-3`}>
                <div className='col-12 col-md-8 col-xxl-7 d-flex flex-column p-4'>
                    <div className='d-flex flex-row justify-content-between '>
                        <h4>{title}</h4>
                        <div className='d-flex my-auto'>
                            <p className='my-auto mx-2 font-post-small'>{formatDate()}</p>
                            <div className='mx-2'>
                                <IconComment/>
                            </div>
                            <p className='m-auto font-post-small'>0 Comentarios</p>
                        </div>

                    </div>
                    <div className='card-post-body my-auto'>
                        <p>{description}</p>
                    </div>
                </div>

                <div className='d-none d-md-flex flex-fill'>
                    <div className='d-flex flex-row m-auto w-100'>
                        <div className='d-flex col-12 col-xl-6 col-xxl-8 flex-column justify-content-between'>
                            <div>
                                <h4 className='manga-title'>{forum_title}</h4>
                                <p>{manga_author}</p>
                            </div>
                            <div className='d-flex flex-row'>
                                {
                                    genders.map((gender, key) => (
                                        <p key={key} className='badge'>{gender.name}</p>
                                    ))
                                }
                            </div>
                            <div className='d-flex my-2 my-xl-0'>
                                <div className='mini-avatar-container'>
                                    {
                                        userAccount.avatar_url ?
                                            <img className='bg-primary' src={userAccount.avatar_url} alt=""/>
                                            :
                                            <div className='d-flex h-100'>
                                                <div className='m-auto'>
                                                    <Puff width="10" color={'white'}/>
                                                </div>
                                            </div>
                                    }
                                </div>
                                <p className='my-auto mx-2 '>{userAccount.username}</p>
                            </div>
                        </div>
                        <div className='flex-fill my-auto d-none d-xl-block '>
                            <div className='medium-avatar-container'>
                                {forum_portrait_url ?
                                    <img className='' src={forum_portrait_url} alt=""/>
                                    :
                                    <div className='d-flex h-100'>
                                        <div className='m-auto'>
                                            <Puff width="50" color={'white'}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 */