import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyCi6EQOaXK9cHMImYFSKiPa_oGgObq8Qio",
    authDomain: "fire.acarye.com",
    projectId: "gracefield-6d2f5",
    storageBucket: "gracefield-6d2f5.appspot.com",
    messagingSenderId: "492451088708",
    appId: "1:492451088708:web:404586dc5bd661d9eb6f11",
    measurementId: "G-CKZ4EHRBF8"
};

export let Firebase = firebase.initializeApp(firebaseConfig) ;
export let googleProvider = new firebase.auth.GoogleAuthProvider();

export let firebaseAnalytics = firebase.analytics();

