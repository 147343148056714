import React from 'react';
import {store} from "../../Redux";
import {useNavigate} from "react-router-dom";

function MyPost({post}) {

    const navigate = useNavigate();

    async function goToPostPage() {

        store.dispatch({
            type: 'SET_POST',
            post,
        })

        navigate(`/p/${post.id}`)
    }


    return (
        <div className='my-post-card' onClick={() => goToPostPage()}>

            <p>{post.title}</p>
            <p className='body-text'>{post.description}</p>

            <div className='my-post-card-footer'>
                <div className='item'>
                    <p>0</p>
                    <span className="material-icons">share</span>
                </div>
                <div className='item'>
                    <p >{post.count_comment}</p>
                    <span className="material-icons-sharp">question_answer</span>
                </div>
                <div className='item'>
                    <p>0</p>
                    <span className="material-icons-outlined">favorite_border</span>
                </div>
            </div>

        </div>
    );
}

export default MyPost;