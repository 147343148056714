import React from 'react';

export default function CardNews({img, title, description, link}) {

    let title2 = title === undefined ? ' ' : title;
    return (
        <div className='card-news me-3' onDoubleClick={() => window.open(link)}>
            <img
                src={img !== undefined ? img : 'https://i2.wp.com/codigoespagueti.com/wp-content/uploads/2020/12/chika-fujiwara-anime.jpg?fit=1280%2C720&quality=80&ssl=1'}
                alt=""/>
            <div className='d-flex flex-column card-news-body px-2'>
                <div className='my-auto'>
                    <p>Fuente somoskudasai</p>
                    <h6>{title2}...</h6>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

