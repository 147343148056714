import React from 'react';
import {formatDate} from "../../Js/moment-custom";

function Comment({comment}) {
    return (
        <div className='comment'>
            <header>
                <div className='avatar-sm-container'>
                    <img src={comment.userAccount.avatar_thumbnails.sm} alt=""/>
                </div>
                <p>{comment.userAccount.username}</p>
                <p>{formatDate(comment.timestamp)}</p>
            </header>
            <div className='comment-body'>
                <p>
                    {comment.comment}
                </p>
            </div>
        </div>
    );
}

export default Comment;