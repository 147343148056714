import React from 'react';
import ButtonCard from "../ButtonCard";

export default function LoginNavbar({login}) {

    return (
        <div className='d-flex justify-content-between '>
            <div>
                <ButtonCard
                    subtitle={'Inicio'}
                    back={true}
                    l={0}/>
            </div>
            <div>
                <ButtonCard
                    path={login ? '/ingresar/crear_cuenta' : '/ingresar'}
                    title={login ? 'Registrarse' : 'Iniciar Sesión'}
                    subtitle={login ? 'Crea tu cuenta' : '¿Ya tienes cuenta?'}
                />
            </div>
        </div>
    );
}
