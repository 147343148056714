import React from 'react';
import {Puff} from "@agney/react-loading";

/**
 *
 * @param id {string}
 * @param state {boolean}
 * @param className {className}
 * @param icon {JSX.Element}
 * @param label {String}
 * @param type {String}
 * @param func {function}
 * @param loading {boolean}
 * @param size {String}
 * @param data_dismiss {string}
 * @param data_bs_toggle {string}
 * @param data_bs_target {string}
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button({
                                   id,
                                   state,
                                   className,
                                   icon,
                                   label,
                                   type,
                                   func,
                                   loading,
                                   data_dismiss,
                                   size,
                                   data_bs_toggle,
                                   data_bs_target
                               }) {
    return (
        <button id={id} type={type || 'submit'}
                onClick={func}
                data-bs-dismiss={data_dismiss || ''}
                data-bs-toggle={data_bs_toggle || ''}
                data-bs-target={data_bs_target || ''}
                className={`button button-${size || ''} p-2 d-flex justify-content-center ${className || 'button--state-success'} ${state ? 'button--state-disabled' : ''}`}
                disabled={state || false}>
            <div className=''>
                {loading ?
                    <Puff width="24" color='#fff'/> :
                    icon
                }
            </div>
            <div className='my-auto'>
                {label}
            </div>
        </button>
    );
}
