export const UserDispatchToProps = dispatch => ({

    setUserAct(user) {
        dispatch({
            type: 'AUTHENTICATE',
            user: {...user}
        })
    },

    setPostAct(myPost) {
        dispatch({
            type: 'SET_MY_POSTS',
            myPost
        })
    }

});
