const initialState = {
    post: {
        id: null,
        title: "",
        description: "",
        timestamp: "",
        forum_title: "",
        manga_author: "",
        manga_genders: [{
            name: ""
        }],
        forum_portrait_url: "",
        userAccount: {
            username: "",
            avatar: null,
            active: false,
            avatar_url: "",
            avatar_thumbnails: {
                xs: "",
                sm: "",
                md: "",
                lg: "",
                xl: "",

            },
        }
    }
}

const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_POST':
            return {
                post: {
                    ...action.post
                }
            };
        case 'CLEAN':
            return initialState;
        default:
            return state;
    }
}

export default PostReducer