import React, {useEffect} from 'react';
import CardNews from "./CardNews";

/**
 *
 * @param array of news
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardNewsGroup({news}) {

    useEffect(() => {
        const slider = document.querySelector('.card-news-group');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };

        let stopDragging = function () {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
                return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    })

    return (
        <div className='card-news-group my-4'>
            {
                news.map((newsItem, key) => {
                    return (
                        <CardNews
                            key={key}
                            description={newsItem.body}
                            title={newsItem.title}
                            img={newsItem.img_src}
                            link={newsItem.link}
                        />
                    );
                })

            }
        </div>
    );
}
