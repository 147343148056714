export const SubNavbarToProps = dispatch => ({

    setMangaAct(manga) {
        dispatch({
            type: 'SET_MANGA',
            manga: {...manga}
        })

    },

    setGendersAct() {

    },

    setActiveAct(gender) {
        gender.active = true
        dispatch({
            type: 'SET_ACTIVE_GENDER',
            gender
        })
    }


})