/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import PageLabel from "../Components/PageLabel";
import LatestMangaCarousel from "../Components/LatestMangaCarousel";
import InfiniteScroll from "react-infinite-scroll-component";
import {Circles} from "@agney/react-loading";
import {getMangasByGender, searchManga} from "../Api/api";
import {connect} from "react-redux";
import {SubNavbarState} from "../Redux";
import {SubNavbarToProps} from "../Redux/Actions/SubNavbarAction";
import useDebounce from "../Api/useDebounce";
import {useNavigate} from "react-router-dom";

function Mangas({genders, gender_active, setActiveAct, setMangaAct}) {

    const navigate = useNavigate();

    const [mangas, setMangas] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [endMessage, setEndMessage] = useState('Ups, parece que has llegado al final :O');

    const loader = <div className='d-flex justify-content-center'>
        <Circles width="50" color='#0088FF'/>
    </div>;


    const [searchActive, setSearchActive] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const debouncedSearchTerm = useDebounce(searchText, 500);


    useEffect(() => {
        document.title = 'Mangas'
        getMangasByGender(gender_active.name, page).then(res => {
            if (res.status !== 200) {
                throw Error;
            }
            return res.json();
        }).then(data => {
            if (data.data[0] === undefined) {
                setHasMore(false)
                setEndMessage('Ups, parece no existen mangas :O')
            } else {
                setMangas(data.data)
                setPage(1)
            }
            setMangas(data.data);
            setPage(page + 1);
        });
        return () => {
            document.title = 'Gracefield'
        }
    }, []);


    useEffect(() => {
        setMangas([])
        setPage(0)
        setHasMore(true)
        callApi(gender_active.name, 0)

        return () => {
            setMangas([])
        }

    }, [gender_active])


    function callApi(gender, page) {
        getMangasByGender(gender, page).then(res => {
            if (res.status !== 200) {
                throw Error('!UPS');
            }
            return res.json();
        }).then(data => {
            setMangas(data.data)
            setPage(page + 1)
        }).catch(() => {
            setHasMore(false)
        });

    }


    function handleInfiniteScroll() {
        if (page !== 0 && !searchActive) {
            getMangasByGender(gender_active.name, page).then(res => {
                if (res.status !== 200) {
                    throw Error;
                }
                return res.json();
            }).then(data => {
                if (data.data.length === 0) {
                    setHasMore(false);
                }
                setMangas(mangas.concat(data.data));
                setPage(page + 1);
            });
        } else if (page !== 0) {

            searchManga(searchText, page).then(res => {
                if (res.status !== 200) {
                    throw Error('UPS')
                }
                return res.json();
            }).then((data) => {
                setMangas(mangas.concat(data.data))
                setPage(page + 1)
            }).catch(() => {
                setHasMore(false)
            })
        }

    }


    function handleSearchBar(evt) {
        let search = evt
        if (search.length >= 3 && search.length <= 255) {
            setSearchLoading(true)

            searchManga(search, 0).then(res => {
                if (res.status !== 200) {
                    throw Error('UPS')
                }
                return res.json();
            }).then((data) => {
                if (data.data.length < 10) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }
                setMangas(data.data)
                setPage(page + 1)
                setSearchActive(true)
                setSearchLoading(false)
                setSearchText(search)
            }).catch(() => {
                setSearchLoading(false)
                setMangas([])
                setHasMore(false)
                setEndMessage('No Hemos podido encontrar lo que andas buscando.')
            })
        }

    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setSearchLoading(true);
            setHasMore(true)
            setSearchActive(true);
            handleSearchBar(debouncedSearchTerm);
        } else if (debouncedSearchTerm.trim() === '') {
            callApi(gender_active.name, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, gender_active.name])


    function goToManga(manga) {
        setMangaAct(manga);
        navigate(`/m/${manga.name}`)

    }

    return (
        <div>
            <PageLabel title='Mangas'/>
            <p className='subtitle'>Infórmate sobre tu manga favorito, como también, únete a la comunidad de foros
                increíbles que podrás encontrar.</p>
            <LatestMangaCarousel/>

            <div className='mangas-container'>
                <div className='nav-bar'>
                    <div className='nav-bar-gender '>
                        {
                            genders.map((gender, key) => (
                                <p key={key} className={gender.active ? 'active' : ''}
                                   onClick={() => setActiveAct(gender)}>{gender.name}</p>
                            ))
                        }
                    </div>
                    <div className='nav-bar-search'>
                        <input type="text" placeholder={'Buscar...'} onChange={evt => setSearchText(evt.target.value)}/>
                    </div>
                </div>
            </div>

            <InfiniteScroll next={() => handleInfiniteScroll()}
                            hasMore={hasMore}
                            loader={loader}
                            dataLength={mangas.length}
                            scrollableTarget='scrollableMiddleContainer'
                            style={{overflowX: "hidden"}}
                            endMessage={
                                <p className='text-center my-4'>
                                    <b>{endMessage}</b>
                                </p>
                            }
            >
                <div className='card-manga-container row '>
                    {

                        searchLoading ?
                            null :
                            mangas.map((manga, key) => (
                                <div className='card-manga' key={key}>
                                    <div className='card-manga-header'
                                         onClick={() => goToManga(manga)}>
                                        <div className='card-manga-header-img-container'>
                                            <img src={manga.portrait_thumbnails.lg} alt=""/>
                                        </div>
                                        <p>{manga.author}</p>
                                        <h5>{manga.name}</h5>
                                    </div>

                                    <div className='card-manga-body'>
                                        <p>{manga.description_es}</p>
                                    </div>
                                </div>
                            ))}
                </div>


            </InfiniteScroll>


        </div>
    );


}

export default connect(SubNavbarState, SubNavbarToProps)(Mangas);
