const initialState = {
    user: {
        username: '',
        email: '',
        count_post: 0,
        avatar: '',
        avatar_url: '',
        isAuth: false,
        token: '',
        myPost: [
            {
                title: '',
                timestamp: '',
            }
        ],
        avatar_thumbnails: {
            xs: "",
            sm: "",
            md: "",
            lg: "",
            xl: "",
        },
    },

}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTHENTICATE':
            return {
                isAuth: action.isAuth,
                token: action.token,
                user: action.user,
            };
        case 'SET_MY_POSTS':
            return {
                user: {
                    ...state.user,
                    myPost: action.myPost,
                }
            }
        case 'LOGOUT':
            return initialState;
        case 'CLEAN':
            return initialState;
        default :
            return state
    }
}

export default UserReducer;
