import React, {useEffect, useState} from 'react';
import {getLatestMangas} from "../Api/api";
import {useNavigate} from "react-router-dom";
import {SubNavbarToProps} from "../Redux/Actions/SubNavbarAction";
import {SubNavbarState} from "../Redux";
import {connect} from "react-redux";

function LatestMangaCarousel({setMangaAct}) {

    const navigate = useNavigate();
    const [mangas, setMangas] = useState([]);

    useEffect(() => {
        callApi();

        return () => {
            setMangas([]);

        }
    }, []);

    function callApi() {
        getLatestMangas().then(res => {
            if (res.status !== 200) {
                throw Error
            }
            return res.json()
        }).then(data => {
            setMangas(data.data);
        }).catch(() => {

        });
    }

    function goToManga(manga) {
        setMangaAct(manga);
        navigate(`/m/${manga.name}`)
    }

    return (
        <div id="carouselExampleCaptions" className="carousel slide carousel-custom" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {mangas.length === 0 ?
                    null :
                    mangas.map((manga, key) => (
                        <button key={key} type="button" data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={key} className={key === 0 ? 'active' : ''}
                                aria-current={mangas.length === 0 ? 'true' : ''}
                                aria-label={`Slide ${key}`}/>
                    ))
                }

            </div>
            <div className="carousel-inner">

                {mangas.length === 0 ?
                    null :
                    mangas.map((manga, key) => (
                        <div onClick={() => goToManga(manga)} key={key}
                             className={`carousel-item ${key === 0 ? 'active' : ''}`} data-bs-interval="4000">
                            <div className='carousel-img-container'>
                                <img src={manga.banner_thumbnails.xxl} className="d-block" alt="..."/>

                            </div>
                            <div className="carousel-caption carousel-item-info  ">
                                <h5>{manga.name}</h5>
                                <p>{manga.author}</p>
                            </div>
                        </div>

                    ))
                }

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default connect(SubNavbarState, SubNavbarToProps)(LatestMangaCarousel);