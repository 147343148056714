import React, {useEffect, useState} from "react";
import {getNews, getPosts} from "../Api/api";
import CardNewsGroup from "../Components/Cards/CardNewsGroup";
import {Puff} from "@agney/react-loading";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonC from "../Components/Skeletons/SkeletonC";
import CardPost from "../Components/Cards/CardPost";
import NewPostBar from "../Components/NewPostBar";
import PostForm from "../Components/Forms/PostForm";
import Modal from "../Components/Modal";


export default function Home() {

    const [posts, setPosts] = useState([]);
    const [errorNews, setErrorNews] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [endMessage, setEndMessage] = useState('No te quedan mas publicaciones pendiente');

    useEffect(() => {
        getPosts(0).then(res => {
            return res.json();
        }).then(data => {
            if (data.data[0] === undefined) {
                setHasMore(false)
                setEndMessage('No existen publicaciones por el momento, pero no te preocupes, puedes ser el primero :)')
            } else {
                setPosts(data.data)
                setPage(1)
            }
        }).catch(() => {
            console.log('jeje');
            setHasMore(false)
        })
        return () => {
            setPosts([])
        }
    }, [])


    function handleInfiniteScroll() {
        getPosts(page).then(res => {
            return res.json();
        }).then(data => {
            if (data.data.length === 0) {
                setHasMore(false);
            }
            setPosts(posts.concat(data.data))
            setPage(page + 1)
        })
    }


    const [news, setNews] = useState([]);

    useEffect(() => {
        getNews().then(res => {
            if (res.status !== 200) {
                throw Error;
            }
            return res.json();
        }).then(data => {
            setNews(data.data);
        }).catch(() => {
            setNews([])
            setErrorNews(true)
        })
        return () => {
            setNews([])
        }

    }, []);

    return (
        <div>

            <div className={`d-flex ${errorNews ? 'd-none' : ''} `}>
                <span className="material-icons">adjust</span>
                <h5>Noticias</h5>
            </div>
            {news[0] !== undefined ? <CardNewsGroup news={news}/> : errorNews ?
                null
                :
                <div className='d-flex  w-100 my-3' style={{height: 169}}>
                    <div className='m-auto'>
                        <Puff width="50" color='#fff'/>
                    </div>
                </div>

            }

            <div className='d-flex'>
                <span className="material-icons">scatter_plot</span>
                <h5>Publicaciones</h5>
            </div>

            <div className='mt-3'>
                <NewPostBar/>
            </div>

            <InfiniteScroll
                dataLength={posts.length} //This is important field to render the next data
                next={() => handleInfiniteScroll()}
                hasMore={hasMore}
                scrollThreshold={0.65}
                loader={<SkeletonC className='card-post-skeleton my-3'/>}
                scrollableTarget='scrollableMiddleContainer'
                endMessage={
                    <p className='text-center my-4'>
                        <b>{endMessage}</b>
                    </p>
                }
            >
                {posts.map((post, index) => (
                    <CardPost post={post} key={index} index={index}/>
                ))}
            </InfiniteScroll>

            <Modal id={'create-post-modal'}>
                <PostForm post={{}} edit={false}/>
            </Modal>


        </div>


    );


}