import React from 'react';


/**
 *
 * @param label {String}
 * @param placeholder {String}
 * @param icon {JSX.Element}
 * @param count {int}
 * @param type {String} text || email || password
 * @param id {String}
 * @param errors {Object}
 * @param onChange {function}
 * @param disable {boolean}
 * @param onBlur {function}
 * @param transparent {boolean}
 * @param my {String} margin => 0,1,2,3,4,5
 * @param value
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export default function Input({
                                  label,
                                  placeholder,
                                  icon,
                                  count,
                                  type,
                                  id,
                                  errors,
                                  onChange,
                                  disable,
                                  onBlur,
                                  value,
                                  transparent,
                                  className,
                                  my
                              }) {
    return (
        <div className={`d-flex flex-column my-${my || '2'} ${className || ''}`}>
            <label htmlFor={id} className='my-1'>{label}</label>
            <div className={`input-custom d-flex ${transparent ? 'bg-transparent' : ''} `}>
                <input onChange={onChange}
                       value={value}
                       disabled={disable}
                       className='p-2 my-auto '
                       autoComplete="new-password"
                       id={id}
                       type={type || 'text'}
                       onBlur={event => onBlur(event.target.value)}
                       placeholder={placeholder}/>
                {icon}
            </div>
            <div className='d-flex'>
                <p className='my-1 error-helper' style={{fontWeight: 100}}>{errors}</p>
                <p className='ms-auto my-1 ' style={{fontWeight: 300, fontStyle: "italic",fontSize:'1rem'}}>{count || ' '}</p>
            </div>
        </div>
    );
}
