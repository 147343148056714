import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {store, UserState} from "../../Redux";
import {UserDispatchToProps} from "../../Redux/Actions/UserAction";
import {connect} from "react-redux";

function LeftSidebar({user}) {
    const navigate = useNavigate();

    async function logout() {
        localStorage.clear();
        store.dispatch({
            type: 'LOGOUT'
        });

        navigate('/', {replace: true});
    }

    return (
        <div className='left-sidebar'>

            <div className='mb-3 d-flex'>
                <svg className='mx-auto' xmlns="http://www.w3.org/2000/svg" width="85%" height="85%"
                     viewBox="0 0 59.989 59.989">
                    <path id="Path_161" data-name="Path 161"
                          d="M59.989,0H0V59.989H59.989ZM43.722,39,39,43.72l-9,9L25.27,48l9-9-4.278-4.28-9,9L16.268,39l9-9-4.278-4.278-9,9L7.264,29.994l9-9,4.724-4.724,9-9,4.724,4.724-9,9,4.278,4.28,9-9,4.724,4.724-9,9L39,34.272l9-9,4.724,4.724Z"
                          fill="#c270ff"/>
                </svg>
            </div>

            <NavLink end to='/' activeClassName='active'>
                <span menu-name='Inicio' className="material-icons-round ">dashboard</span>
            </NavLink>

            <NavLink end to='/m/' activeClassName='active' className='mt-4'>
                <span menu-name='Mangas' className="material-icons-round">layers</span>
            </NavLink>

            <NavLink end to='/test' activeClassName='active' className='mt-4'>
                <span menu-name='Foros' className="material-icons-round">forum</span>
            </NavLink>


            {
                user.isAuth ?
                    <>
                        <NavLink end to='/pr' activeClassName='active' className='mt-4'>
                            <span menu-name='Mi perfil' className="material-icons-round">account_circle</span>
                        </NavLink>
                        <div className='mt-auto' onClick={logout}>
                            <span menu-name='Cerrar Sesión' className="material-icons-round">power_settings_new</span>
                        </div>
                    </> :
                    <>
                        <NavLink end to='/ingresar' activeClassName='active' className='d-lg-none  mt-auto mb-2'>
                            <span menu-name='Ingresar' className="material-icons-round">login</span>
                        </NavLink>
                    </>
            }

        </div>
    );

}

export default connect(UserState, UserDispatchToProps)(LeftSidebar)

