import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {UserState} from "../../Redux";
import {UserDispatchToProps} from "../../Redux/Actions/UserAction";
import ButtonCard from "../ButtonCard";
import Button from "../Buttons/Button";
import {Firebase, googleProvider} from "../../Configs/Firebase";
import {getMyLatestPost, isEmailAlreadyInUse, socialAuthenticate} from "../../Api/api";
import MyPost from "../Cards/MyPost";
import SkeletonC from "../Skeletons/SkeletonC";

function RightSidebar({user, setUserAct}) {

    const [isLoading] = useState(false);

    const [myPost, setPosts] = useState([]);

    useEffect(() => {
        if (user.isAuth) {
            apiCall();
        }

    }, [user.isAuth])


    function apiCall() {

        getMyLatestPost().then(res => {
            if (res.status !== 200) {
                throw Error;
            }
            return res.json();
        }).then(data => {
            setPosts(data.data);
        })


    }

    function googleSignIn() {
        Firebase.auth().signInWithPopup(googleProvider).then(async res => {

            let googleUser = res.user
            let idToken = googleUser.Aa;
            let email = googleUser.email
            let username = null;

            let isEmailInUse = await isEmailAlreadyInUse(email);
            if (isEmailInUse) {
                socialAuthenticate(username, idToken).then(res => {
                    return res.json();
                }).then(data => {
                    data.data.isAuth = true;
                    setUserAct(data.data);
                }).catch(e => {
                    console.log(e.message);
                })
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {
                user.isAuth ?
                    <div className='right-sidebar '>
                        <header className='right-sidebar-header'>

                            <div className='avatar-xl-container'>
                                <img
                                    src={user.avatar_thumbnails.xl}
                                    alt=""/>
                            </div>
                            <div className='me-auto'>
                                <h4>{user.username}</h4>
                                <h5>{user.email}</h5>
                            </div>
                        </header>
                        <div className='w-100 px-3'>
                            <hr/>
                        </div>
                        <div className='right-sidebar-body'>
                            <h6>Mis publicaciones</h6>
                            <div className='my-post-card-container pt-2'>
                                {myPost.length === 0 ?
                                    <>
                                        <SkeletonC className='w-100 h-25 mb-2'/>
                                        <SkeletonC className='w-100 h-25 mb-2'/>
                                        <SkeletonC className='w-100 h-25 mb-2'/>
                                        <SkeletonC className='w-100 h-25 mb-2'/>
                                    </> :
                                    myPost.map((post, key) => (
                                        <MyPost key={key} post={post}/>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                    :
                    <div className='right-sidebar-logout '>
                        <ButtonCard
                            subtitle={'¿Ya tienes cuenta?'}
                            back={false}
                            path='/ingresar'
                            title={'Iniciar Sesión'}
                            float={false}
                        />
                        <div className='d-flex justify-content-center my-2 mx-5 text-center '>
                            <p style={{fontSize: '0.85rem', color: '#989898'}}>También puedes ingresar con redes
                                sociales</p>
                        </div>
                        <div className='my-2 d-flex'>
                            <div className='m-auto'>
                                <Button
                                    icon={<GoogleLogo/>}
                                    state={isLoading}
                                    loading={isLoading}
                                    func={googleSignIn}
                                    type='button'
                                    className='bg-white text-center'
                                />
                            </div>

                        </div>


                    </div>
            }

        </>
    );

}


export default connect(UserState, UserDispatchToProps)(RightSidebar);

function GoogleLogo() {
    return (
        <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path fill="#4285F4"
                      d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                <path fill="#34A853"
                      d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                <path fill="#FBBC05"
                      d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                <path fill="#EA4335"
                      d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
            </g>
        </svg>
    );
}