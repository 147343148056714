import React from 'react';
import Button from "../Buttons/Button";
import {formatDate} from "../../Js/moment-custom";

function CardForum({forum}) {
    return (
        <div className='forum-card'>
            <div className='forum-card-img-container'>
                <img src={forum.portrait_thumbnails.xl} alt=""/>
            </div>
            <div className='forum-card-body'>
                <header>
                    <h5 className='semi-bold'>{forum.title}</h5>

                    <div className='sub'>
                        <div>
                            <span className="material-icons">date_range</span>
                            <p>{formatDate(forum.createdAt)}</p>
                        </div>
                        <div>
                            <span className="material-icons">people_alt</span>
                            <p>10k usuarios</p>
                        </div>

                        <div>
                            <span className="material-icons">chat</span>
                            <p>{forum.count_post} Publicaciones</p>
                        </div>
                    </div>
                </header>
                <p className='forum-card-body-description'>{forum.description_es}</p>
                <Button
                    type={'button'}
                    func={() => null}
                    label={'Ir'}
                    icon={<span className="material-icons">arrow_right</span>}

                />
            </div>
        </div>
    );
}

export default CardForum;