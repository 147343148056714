import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import PageLabel from "../Components/PageLabel";
import {connect} from "react-redux";
import {MangaState} from "../Redux";
import {getForumsByManga, getMangaByName, searchForumByManga, searchManga} from "../Api/api";
import {MangaDispatchToProps} from "../Redux/Actions/MangaAction";
import useDebounce from "../Api/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import {Circles} from "@agney/react-loading";
import CardForum from "../Components/Cards/CardForum";

function Manga({manga, setMangaAct}) {

    const {name} = useParams();

    const [forums, setForums] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [endMessage, setEndMessage] = useState('Ups, parece que has llegado al final :O');

    const loader = <div className='d-flex justify-content-center'>
        <Circles width="50" color='#0088FF'/>
    </div>;


    const [searchActive, setSearchActive] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const debouncedSearchTerm = useDebounce(searchText, 500);


    useEffect(() => {
        if (name !== manga.name) {
            getMangaByName(name).then(res => {
                if (res.status !== 200) {
                    throw Error;
                }
                return res.json();
            }).then(data => {
                setMangaAct(data.data);
            }).catch(() => {

            })
        }

    }, [setMangaAct, manga, name]);


    useEffect(() => {
        document.title = name
        getForumsByManga(name, page).then(res => {
            if (res.status !== 200) {
                throw Error;
            }
            return res.json();
        }).then(data => {
            if (data.data[0] === undefined) {
                setHasMore(false)
                setEndMessage('Ups, parece no existen mangas :O')
            } else {
                setForums(data.data)
                setPage(1)
            }
            setForums(data.data);
            setPage(page + 1);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleInfiniteScroll() {
        if (page !== 0 && !searchActive) {
            getForumsByManga(name, page).then(res => {
                if (res.status !== 200) {
                    throw Error;
                }
                return res.json();
            }).then(data => {
                if (data.data.length === 0) {
                    setHasMore(false);
                }
                setForums(forums.concat(data.data));
                setPage(page + 1);
            });
        } else if (page !== 0) {
            searchManga(searchText, page).then(res => {
                if (res.status !== 200) {
                    throw Error('UPS')
                }
                return res.json();
            }).then((data) => {
                setForums(forums.concat(data.data))
                setPage(page + 1)
            }).catch(() => {
                setHasMore(false)
            })
        }

    }


    function callApi(name, page) {
        getForumsByManga(name, page).then(res => {
            if (res.status !== 200) {
                throw Error('!UPS');
            }
            return res.json();
        }).then(data => {
            console.log(data);
            setForums(data.data)
            setPage(page + 1)
        }).catch(() => {
            setHasMore(false)
        });

    }

    function handleSearchBar(evt) {
        let search = evt
        if (search.length >= 3 && search.length <= 255) {
            setSearchLoading(true)

            searchForumByManga(name, search, 0).then(res => {
                if (res.status !== 200) {
                    throw Error('UPS')
                }
                return res.json();
            }).then((data) => {
                if (data.data.length < 10) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }
                setForums(data.data)
                setPage(page + 1)
                setSearchActive(true)
                setSearchLoading(false)
                setSearchText(search)
            }).catch(() => {
                setSearchLoading(false)
                setForums([])
                setHasMore(false)
                setEndMessage('No Hemos podido encontrar lo que andas buscando.')
            })
        }

    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setSearchLoading(true);
            setHasMore(true)
            setSearchActive(true);
            handleSearchBar(debouncedSearchTerm);
        } else if (debouncedSearchTerm.trim() === '') {
            callApi(name, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm])

    return (
        <div>
            <PageLabel title='Manga'/>
            <div className='banner-container mt-3 mb-2'>
                <img src={manga.banner_thumbnails.xxl} className="d-block" alt="..."/>
            </div>
            <h1 className='bold'>{manga.name}</h1>
            <h4 className='light'>{manga.author}</h4>
            <p className='light  my-2'>{manga.description_es}</p>

            <h4 className='bold'>Foros</h4>

            <div className='mangas-container'>
                <div className='nav-bar'>
                    <div className='nav-bar-search'>
                        <input type="text" placeholder={'Buscar...'} onChange={evt => setSearchText(evt.target.value)}/>
                    </div>
                    <div className='nav-text  text-center'>
                        <p className='light' style={{fontSize: '0.9rem'}}>Busca y unete a la cantidad de foros que
                            quieres, communicate y comparte con los demas sobre temas que quieras.</p>
                    </div>

                </div>
            </div>

            <InfiniteScroll next={() => handleInfiniteScroll()}
                            hasMore={hasMore}
                            loader={loader}
                            dataLength={forums.length}
                            scrollableTarget='scrollableMiddleContainer'
                            style={{overflowX: "hidden"}}
                            endMessage={
                                <p className='text-center my-4'>
                                    <b>{
                                        forums.length < 10 ?
                                            forums.length === 0 ? 'No hay foros encontrados :c' : null : endMessage
                                    }</b>
                                </p>
                            }
            >
                <div className='forums-container row'>
                    {searchLoading ?
                        null :
                        forums.map((forum, key) => (
                            <CardForum
                                key={key}
                                forum={forum}

                            />
                        ))
                    }
                </div>

            </InfiniteScroll>

        </div>
    );
}

export default connect(MangaState, MangaDispatchToProps)(Manga);