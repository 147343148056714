const initialState = {
    manga: {
        name: "",
        author: "",
        tomes: 0,
        state: false,
        description: "",
        description_es: "",
        portrait: "",
        banner: null,
        portrait_url: "",
        banner_url: "",
        createdAt: "",
        updatedAt: "",
        genders: [
            {
                name: "Yuri"
            }
        ],
        portrait_thumbnails: {
            xs: "",
            sm: "",
            md: "",
            lg: "",
            xl: "",
            xxl: ""
        },
        banner_thumbnails: {
            xs: "",
            sm: "",
            md: "",
            lg: "",
            xl: "",
            xxl: ""
        },
    }
}

const MangaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MANGA':
            return {
                manga: {
                    ...action.manga
                }
            };
        case 'CLEAN':
            return initialState;
        default:
            return state;

    }
}

export default MangaReducer;