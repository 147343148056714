import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function ButtonCard({back, title, subtitle, l, path,float = true}) {

    const navigate = useNavigate();

    return (
        <div className={`d-flex ${ float? 'button-nav' : 'button-nav-block'} m-3 ${l !== undefined ? 'start-0' : 'end-0'} `}
             onClick={back ? () => navigate('/') : () => navigate(path)}>
            <div className='my-auto ms-auto d-flex'>
                {back ? <span className="material-icons">chevron_left</span> : ''}
            </div>
            <div className='d-flex flex-column me-auto my-auto '>
                <p className='title'>{back ? 'Volver' : title}</p>
                <p className='subtitle'>{subtitle}</p>
            </div>
        </div>
    );

}

