import React, {useState} from 'react';
import {useFormik} from "formik";
import * as yup from "yup";
import Input from "./Input";
import Select from "react-select";
import TextArea from "./TextArea";
import Button from "../Buttons/Button";
import {createPost, editPost, searchForums} from "../../Api/api";
import {store} from "../../Redux";
import {useNavigate} from "react-router-dom";

/**
 *
 * @param post {Object} -  Parámetro obligatorio || {}.
 * @param edit {boolean} -  Habilitar edición de publicación.
 * @returns {JSX.Element}
 * @constructor
 */
export default function PostForm({post, edit}) {
    const navigate = useNavigate();
    const [buttonLabel, setButtonLabel] = useState('Publicar');

    const [value, setValue] = useState(post.forum_title || '');

    const [options, setOptions] = useState(post.id ? [{
        value: post.forum_title || '',
        label: post.forum_title || '',
        img_url: post.forum_portrait_url || '',
    }] : []);

    const [isLoading, setLoading] = useState(false);

    const [img_url, setImg_url] = useState(post.forum_portrait_url || '');

    const formik = useFormik({
        initialValues: {
            title: post.title || '',
            description: post.description || '',
        },
        validationSchema: yup.object({
            title: yup.string().min(3, 'Como mínimo 3 caracteres').required('El titulo es obligatorio').max(80),
            description: yup.string().min(6, 'Como mínimo 6 caracteres').required('El mensaje es obligatorio'),
        }),
        onSubmit: (form) => {
            setButtonLabel("Publicando")
            setLoading(true)
            try {
                if (edit) {
                    //editar publicación
                    let postEdited = {
                        title: form.title,
                        description: form.description,
                        username: post.userAccount.username
                    }
                    editPost(value, post.id, postEdited).then(res => {
                        return res.json();
                    }).then((data => {
                        store.dispatch({
                            type: 'SET_POST',
                            post: data.data
                        });
                        setButtonLabel("Publicando");
                        setTimeout(() => {
                            setButtonLabel("Publicar");
                            setLoading(false)
                            document.getElementById('button-close-modal').click();
                        }, 500);
                    })).catch(() => {
                        setButtonLabel("!UPS");
                        setTimeout(() => {
                            setButtonLabel("Publicar");
                            setLoading(false)
                        }, 500);
                    });

                } else {
                    //crear publicación
                    createPost(value, {
                        title: form.title,
                        description: form.description,
                        username: store.getState().UserReducer.user.username,
                    }).then(res => {
                        if (res.status !== 200) {
                            throw Error('!Ups')
                        }
                        return res.json()
                    }).then((data) => {
                        let title = document.getElementById('title');
                        let description = document.getElementById('description');
                        title.value = '';
                        description.value = '';
                        formik.resetForm({})
                        setButtonLabel("Publicado")
                        store.dispatch({
                            type: 'SET_POST',
                            post: data.data
                        })
                        setTimeout(() => {
                            setLoading(false)
                            setButtonLabel("Publicar")
                            let btn = document.getElementById('button-close-modal');
                            btn.click();
                            navigate(`/p/${data.data.id}`);
                        }, 500);
                    }).catch(() => {
                        setButtonLabel("!Ups")
                        setTimeout(() => {
                            setLoading(false)
                            setButtonLabel("Publicar")
                        }, 500);
                    })
                }

            } catch (e) {

            }
        }
    })

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'blue',
            outline: 'none',
        }),
        control: (control) => ({
            ...control,
            border: 'none',
            backgroundColor: '#51515C',
            color: '#fff',
            height: '50px',
            outline: 'none'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return {...provided, opacity, transition, color: '#fff',};
        },
        input: () => ({
            color: '#fff'
        })
    }

    function handleInputSelect(s) {
        setLoading(true);
        if (s.target.value.trim() !== '' && s.target.value.length >= 3) {
            searchForums(s.target.value).then(res => {
                if (res.status !== 200) {
                    throw Error('');
                }
                return res.json();
            }).then(data => {
                let opts = []
                if (data.data === []) {
                    throw Error('');
                } else {
                    data.data.map(forum => {
                        return opts.push({
                            value: forum.title,
                            label: forum.title,
                            img_url: forum.manga.portrait_url,
                        });
                    })
                    setLoading(false);
                    setOptions(opts);
                }
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    const handleChange = e => {
        setImg_url(e.img_url)
        setValue(e.value)
    }

    return (
        <>

            <div className='modal-header d-flex'>
                <h5 className='my-auto'>Crear Publicación</h5>
            </div>
            <form onSubmit={formik.handleSubmit} autoComplete="off"
                  className='flex-fill d-flex flex-column px-3'>
                <Input
                    value={formik.values.title}
                    id='title'
                    // icon={<IconTitle/>}
                    errors={formik.errors.title}
                    onChange={formik.handleChange}
                    placeholder='Titulo de publicación'
                    type='text'
                    onBlur={() => null}
                />
                <div className='d-flex flex-row'>
                    <Select options={options}
                            defaultValue={options[0] === undefined ? null : options[0]}
                            onKeyDown={(value) => handleInputSelect(value)}
                            onBlur={() => null}
                            className='w-100 test'
                            styles={customStyles}
                            placeholder='Buscar foro'
                            isLoading={isLoading}
                            onChange={handleChange}
                    />
                    <div className='my-auto modal-manga-banner ms-3'>
                        {
                            img_url ?
                                <>
                                    <img src={img_url} alt=""/>
                                    <h5>{value}</h5>
                                </>
                                : null
                        }
                    </div>
                </div>
                <TextArea
                    id='description'
                    value={formik.values.description}
                    placeholder='Titulo de publicación'
                    type='textarea'
                    onChange={formik.handleChange}
                    errors={formik.errors.description}
                    onBlur={() => null}
                />
                <div className="d-flex flex-row justify-content-end mb-3 ">
                    <div className='mx-2'>
                        <Button
                            id={'button-close-modal'}
                            data_dismiss="modal"
                            className='button--state-warn'
                            state={isLoading}
                            type='button'
                            loading={isLoading}
                            label={'Cancelar'}
                        />
                    </div>
                    <div className=''>
                        <Button
                            // icon={<IconSend/>}
                            state={isLoading}
                            loading={isLoading}
                            label={buttonLabel}
                        />
                    </div>
                </div>
            </form>


        </>
    );
}
