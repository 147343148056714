import React from 'react';

export default function Modal({children, id}) {
    return (
        <div className="modal  fade modal-custom " id={id} tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content d-flex flex-column justify-content-between ">
                    {children}
                </div>
            </div>
        </div>
    );
}
