import React, {useState} from 'react';
import LoginNavbar from "../Components/NavBars/LoginNavbar";
import Input from "../Components/Forms/Input";
import Button from "../Components/Buttons/Button";
import {useFormik} from "formik";
import * as yup from "yup";
import {findUser, register} from "../Api/api";

export default function Register() {

    const formik = useFormik({
        initialValues: {
            UserName: '',
            Password: '',
            Email: ''
        },
        validationSchema: yup.object({
            UserName: yup.string().min(3, 'Como mínimo 3 caracteres').required('El nombre es obligatorio').max(30),
            Password: yup.string().min(6, 'Como mínimo 6 caracteres').required('La contraseña es obligatorio').max(25),
            Email: yup.string().email('No es un email valido').required('El email es obligatorio').max(100, 'Email debe ser de maximo 100 caracteres'),
        }),
        onSubmit: (form) => {
            try {
                setLoading(true);
                setButtonLabel('Ingresando');
                setDisableBtn(true)
                let user = {
                    username: form.UserName,
                    email: form.Email,
                    password: form.Password
                }
                register(user).then(res => {
                    return res.json()
                }).then(res => {
                    if (res.code === 400) {
                        setButtonLabel('Nombre de usuario no disponible');
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setDisable(true);
                        setDisableBtn(true)
                        setButtonLabel('Verifica tu email para continuar');
                    }
                })

            } catch (e) {
                setLoading(false);
                setButtonLabel('Ingresar');
            }
        },
        validate: () => {
            if (!formik.isValid) {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
        }
    })

    const [isLoading, setLoading] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Unirme');
    const [disable, setDisable] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    async function handleUserName(value) {
        if (value.trim() === '') {
            return;
        }
        await findUser(value).then(res => {
            return res.json()
        }).then(res => {
            if (res.code === 404) {
                setButtonLabel('Unirme');
                setDisableBtn(false)
            } else {
                setButtonLabel('Nombre de usuario no disponible');
                setDisableBtn(true)
            }
        })
    }

    return (
        <div className='d-flex flex-column vh-100'>
            <LoginNavbar login={false}/>
            <div className='d-flex flex-column m-auto '>
                <h1 className='title-register my-4'>Crea tu cuenta</h1>
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        disable={disable}
                        id='UserName'
                        label='Nombre de usuario'
                        icon={<span className="material-icons">account_circle</span>}
                        placeholder='Nombre de usuario'
                        count={formik.values.UserName.length + '/30'}
                        errors={formik.errors.UserName}
                        onChange={formik.handleChange}
                        onBlur={handleUserName}
                    />
                    <Input
                        disable={disable}
                        id='Email'
                        label='Email'
                        icon={<span className="material-icons">alternate_email</span>}
                        placeholder='Nombre de usuario'
                        count={formik.values.Email.length + '/100'}
                        errors={formik.errors.Email}
                        onChange={formik.handleChange}
                        onBlur={() => null}
                    />

                    <Input
                        disable={disable}
                        id='Password'
                        label='Contraseña'
                        icon={<span className="material-icons">lock</span>}
                        placeholder='*********'
                        count={formik.values.Password.length + '/25'}
                        type='password'
                        errors={formik.errors.Password}
                        onChange={formik.handleChange}
                        onBlur={() => null}
                    />

                    <Button
                        disable={disableBtn}
                        icon={<span className="material-icons">send</span>}
                        state={disableBtn}
                        loading={isLoading}
                        label={buttonLabel}
                        onBlur={() => null}
                    />
                </form>
            </div>
        </div>
    );
}
